import React from "react";
function HeaderLanding() {
  return (
    <>
      <div className="header landing">
        <div className="container">
          <div className="row">
            <div className="col-8">
              <div className="brand-logo">
                <a href="/">
                  <img src="/images/logo.png" alt="" className="logo-primary" />
                </a>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-end align-self-center">
              <div className="signin-btn">
                <a href="#signup" className="btn btn-primary">
                  Sign up
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HeaderLanding;
