import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./app.scss";

import { HomePage, NotFoundPage } from './components';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

