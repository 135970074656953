import React, { useState } from "react";

import * as Yup from "yup";

import HeaderLanding from "./HeaderLanding";
import Features from "./Features";
import AboutUs from "./AboutUs";
import EmailForm from "./EmailForm";

import { registrationService } from "../services";
import MainText from "./MainText";
import ImageBlurb from "./ImageBlurb";

const HomePage: React.FunctionComponent = (props: any) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Please enter your email address").email("Please enter a valid email address"),
  });

  const onSubmit = async (data: any, actions: any) => {
    const { setSubmitting } = actions;
    try {
      setSubmitting(true);
      const response = await registrationService.add(data.email);
      setSubmitting(false);
      if (response.success) {
        setSubmitted(true);
      } else {
        setSubmitted(false);
        throw Error(`API error: ${response.error.message}`);
      }
    } catch (error: any) {
      console.error("error saving registration", { error });
      setStatus(error.message);
    }
  };

  return (
    <>
      <div className="container-fluid mt-2">
        <HeaderLanding />

        <div className="row">
          <div className="col">
            <div className="container g-3">
              <div className="row">
                <div className="col-md-6">
                  <MainText />
                </div>
                <div className="col-md-6">
                  <ImageBlurb />
                </div>
              </div>

              <EmailForm
                submitted={submitted}
                initialValues={initialValues}
                onSubmit={onSubmit}
                status={status}
                validationSchema={validationSchema}
              />
            </div>
          </div>
        </div>

        <div className="row about-us-curve"></div>
        <div className="row about-us-container">
          <div className="col">
            <AboutUs />

            {/* cards */}
            <Features />
          </div>
          <div className="footer"></div>
        </div>
      </div>
    </>
  );
};

export { HomePage };
