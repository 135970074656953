import React from "react";

function MainText() {
  return (
    <>
    <div className="coming-soon mt-3 mb-3">Coming soon...</div>

    <div className="main-text main-text-lg">
      <span className="black">
        A totally new <br />
        paradigm for NFT&apos;s.
      </span>
      <br />
      <span className="blue">
        Let&apos;s flip the
        <br /> model.
      </span>
    </div>

    <div className="main-text main-text-sm">
      <span className="black">
        A totally new <br />
        paradigm for NFT&apos;s.
      </span>
      <br />
      <span className="blue">
        Let&apos;s flip the model.
      </span>
    </div>
    </>
  );
}

export default MainText;
