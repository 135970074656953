import axios from 'axios';
import { config } from '../config';


class RegistrationService {
  constructor (protected apiBaseUrl: string) {}

  async add (email: string) {
    const url = `${apiBaseUrl}/register-email`
    const result = await this.post<any>(url, {email});
    return result;
  }

  async post<T>(url:string, item: object): Promise<T> {
    try {
      const response: any = await axios.post<any>(url, item);
      if (response.error) {
        throw Error(response.error.message);
      }
      return response.data as T;
    } catch (error: any) {
      console.error('error in post', {error});
      throw Error(error);
    }    
  }  
}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}`;
const registrationService = new RegistrationService(apiBaseUrl);
export { registrationService }