/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

function EmailForm({
  submitted,
  initialValues,
  validationSchema,
  onSubmit,
  status,
}: {
  submitted: any;
  initialValues: any;
  validationSchema: any;
  onSubmit: any;
  status: any;
}) {
  return (
    <div className="container email-container g-0">
      {submitted ? (
        <div className="alert alert-primary">Thanks for registering! You will be among the first to know about MintDesk&apos;s exciting new offerings. </div>
      ) : (
        <>
          <div className="row mt-3">
            <a id="signup" href="/"></a>
            <div className="col text-start sign-up-now">
              <span>Sign up now for our pre-launch marketplace invite.</span>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col">
              <div className="email-box p-3 shadow">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                  {({ isSubmitting, errors, touched, setSubmitting }) => {
                    return (
                      <Form>
                        <div className="container g-0">
                          <div className="row">
                            <div className="col-9">
                              <Field
                                className={
                                  "form-control" + (errors && errors["email"] && touched && touched["email"] ? " is-invalid" : "")
                                }
                                name={"email"}
                                placeholder="you@email.com"
                              />
                              {errors && <ErrorMessage name={"email"} component="div" className="invalid-feedback" />}
                            </div>
                            <div className="col-3 text-end align-self-center">
                              <button disabled={isSubmitting} type="submit" className="btn btn-primary submit-email">
                                Submit
                              </button>
                            </div>
                          </div>
                          {status && (
                            <div className="row mt-3">
                              <div className="col">
                                <div className="alert alert-warning">{status}</div>
                              </div>
                            </div>
                          )}
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col text-start never-sell">
              <span>We will never sell your email</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default EmailForm;
