import React from "react";

export default function ImageBlurb() {
  return (
    <div className="container mt-2">
      <div className="row">
        <div className="col">
          <div className="card">
            <img className="promote-image" src="/images/awarded.png" alt="Mintdesk NFT's promote YOU" />
          </div>
        </div>
      </div>
    </div>
  );
}
