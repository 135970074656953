import React, { ReactNode } from 'react';

interface IFeature {
  title: string,
  text: ReactNode []
}

const features: IFeature [] = [
  {
    title: 'Our Experience',
    text: [
      <>Our team has many years of experience building large scale digital platforms that routinely served
      hundreds of millions of users across the web.</>,
      <>The last company we created generated over <span className="fw-bold">$350 Million</span> in digital
      revenues.
      </>
    ]
  },
  {
    title: 'Innovation is in our DNA',
    text: [
      <>We&apos;ve pioneered some of the most unique monetization solutions on the Web. We love creating new
      markets.</>
    ],
  },
  {
    title: 'Stealth Mode',
    text: [
      <>Because we are building something very unique in a competitive space, we&apos;re keeping some things
      secret right now. Enter your email above to join the revolution early!</>
    ],
  }
];

function Features() {
  return(
    <div className="container">
    <div className="row">
      {features.map((feature: IFeature, i: number) => <>
        <div className="col col-12 col-lg-4 col-xl-4 col-md-12 col-xs-12 col-sm-12 mt-2">
        <div className={`card card-feature card-feature-${i + 1}`}>
          <div className="card-body">
            <h5 className="card-title">{feature.title}</h5>
            {feature.text.map((text: ReactNode) => <><p className="card-text">{text}</p></>)}
          </div>
        </div>
      </div>
      </>)}
    </div>
  </div>    
  )
}

export default Features;