import { IConfig } from '../interfaces';

let config: IConfig;

let env: any = process.env.REACT_APP_ENV_CONFIG;
const envs = ['development','test','production'];
//default to first
if (!envs.includes(env)) {
    env = envs[0];
}
//dynamically load by name
config = require('./' + env).default;

export { config };
