import React from 'react';

function AboutUs () {
  return(
    <div className="container about-us my-lg-5">
    <div className="about-us-title mt-2">ABOUT US</div>
    <div className="about-us-subtitle mt-2">
    We&apos;re building an NFT platform with built-in utility and valuation metrics
    </div>
  </div>    
  );
}

export default AboutUs